<template>
  <div v-if="!$q.loading.isActive">
    <!-- gallery is used to view images in popup -->
    <Gallery
      v-show="showPreviewModal"
      @close="showPreviewModal = false"
      :item="item"
      :total="items.length"
      :show-delete="false"
      :show-save="false"
      @next="next"
      @prev="prev"
      :current="currentFocus"
    />

    <section id="partner-collection-intro">
      <p class="note" style="position: relative;bottom:0" v-if="owner === uuid">
        You are owner
      </p>

      <div class="row q-mx-auto q-py-md justify-between items-start">
        <div class="col">
          <img
            style="max-width:300px"
            class="float-left on-left"
            :src="thumb"
          />
          <h4>{{ partner.name }}: {{ collection.name }}</h4>
          <h6>{{ collection.introduction }}</h6>
          <p class="description" v-html="collection.description"></p>
        </div>
      </div>
    </section>

    <q-page-sticky position="bottom-right" :offset="[0, 0]">
      <div class="bg-white q-pa-md q-gutter-sm">
        <q-btn
          no-caps
          @click="activeSelection = []"
          v-show="activeSelection.length"
          class="q-my-md"
          label="Cancel"
        />
        <q-btn
          no-caps
          @click="addMultiple"
          v-show="activeSelection.length"
          class="q-my-md"
          color="primary"
          :label="
            activeSelection.length <= 1
              ? 'Add selection to MySources'
              : 'Add selections to MySources'
          "
        />
      </div>
    </q-page-sticky>

    <section id="card-holder">
      <div class="row q-mx-auto justify-start items-start" id="collections">
        <div class="row q-gutter-md">
          <div :key="i" v-for="(card, i) in items" class="column">
            <Card
              :partner="partner"
              :activeSelection="activeSelection"
              :item="card"
              :title="card.title"
              :index="i"
              :uuid="card.uuid"
              :thumb="card.url"
              type="Source"
              csstype="source"
              :showAddToMyHistoriana="uuid === owner ? false : true"
              @addToMyHistoriana="addToMyHistoriana"
              @toggleSelect="toggleSelect"
              :btnSelect="uuid === owner ? false : true"
              :btnEditModal="uuid === owner ? false : false"
              @cardClicked="showPreview(card, i)"
              >{{ card.description }}</Card
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { API } from '../tic';
import Card from '../tags/Card';
import Gallery from '../tags/Gallery';

import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'PartnerCollection',
  components: { Card, Gallery },

  props: {
    partnerSlug: {
      type: String,
      default: null
    },

    collectionSlug: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      currentFocus: 0,
      item: {},
      items: [],
      collection: {},
      partner: {},
      thumb: null,
      owner: null,
      activeSelection: [],
      showPreviewModal: false
    };
  },

  computed: {
    ...mapGetters('user', ['uuid'])
  },

  mounted() {
    let vm = this;
    this.$q.loading.show({
      delay: 100 // ms
    });
    console.log('** MOUNT PARTNER COLL ');
    API.post('/collection/by-partner', {
      partner: this.partnerSlug,
      collection: this.collectionSlug
    })
      .then(r => {
        console.log('respons: ', r);
        this.items = r.data.items;
        this.collection = r.data.collection;
        this.partner = r.data.partner;
        this.owner = r.data.owner;
        this.thumb = r.data.thumb;
        this.$q.loading.hide();
      })
      .catch(e => {
        vm.$q.loading.hide();
        vm.$q.dialog({
          title: '<i class="fa fa-bomb"></i>&nbsp;Error',
          message: 'No data found, you are redirected to the index.',
          html: true,
          ok: {
            color: 'primary'
          }
        });
        vm.$router.replace('/partners');
      });
  },

  methods: {
    prev() {
      //console.log('prev: ', this.items[this.currentFocus - 1])
      if (this.items[this.currentFocus - 1] !== undefined) {
        this.item = this.items[this.currentFocus - 1];
        if (this.item.url) {
          this.image = this.item.url;
        }
        this.currentFocus--;
      }
    },
    next() {
      //console.log('next: ', this.items[this.currentFocus - 1])
      if (this.items[this.currentFocus + 1] !== undefined) {
        this.item = this.items[this.currentFocus + 1];
        if (this.item.url) {
          this.image = this.item.url;
        }
        this.currentFocus++;
      }
    },
    showPreview: function(item, offset) {
      console.log('SHOW SOURCE DETAILS', item);
      console.log('item: ', item);
      console.log('offset: ', offset);
      this.showPreviewModal = true;
      this.item = item;

      if (this.item.url) {
        this.image = this.item.url;
      }

      this.currentFocus = offset;
      //this.currentFocus = offset
      this.showPreviewModal = true;
    },

    toggleSelect(e, i) {
      console.log('toggleSelect: ', e);
      console.log('------ :', i);
      let me = this;
      if (_.includes(me.activeSelection, e)) {
        // before eslint XXX
        // me.multiSelect = me.multiSelect.filter( (v) => { return v===e ? false : true })
        console.log('already in selection, remove');
        me.activeSelection = me.activeSelection.filter(v => {
          return v !== e;
        });
      } else {
        console.log('add to selection');
        me.activeSelection.push(e);
      }
    },

    addMultiple() {
      let me = this;
      console.log('user: ', this.$store.getters['user/uuid']);
      let user = this.$store.getters['user/uuid'];
      let sources = this.multiSelect;
      API.post('/addSources', {
        user: user,
        sources: this.activeSelection
      }).then(response => {
        this.$store.dispatch('sendFeedback', {
          msg: `Added ${this.activeSelection.length} items to MySources`,
          status: 'ok'
        });
        me.multiSelect = [];
      });
    },

    addToMyHistoriana(item, type) {
      console.log('add: ', item, type);
      let user = this.$store.getters['user/uuid'];
      let sources = this.multiSelect;
      API.post('/addSource', {
        user: user,
        source: item.uuid
      }).then(response => {
        this.$store.dispatch('sendFeedback', {
          msg: `Added to MySources`,
          status: 'ok'
        });
      });
    }

    // voor nu geen (i) op de Card
    // renderMeta(data) {
    //   let html = `<img style="float:right;width: 75px; height: 75px" src="${data.partner_logo}"/><h3>${data.partner_name}</h3>`

    //   let table = '<table class="meta-info" valign=top>'

    //   for (const [key, value] of Object.entries(data)) {
    //     if (!key.startsWith("partner_")) {
    //       table =
    //         table +
    //         `<tr><td class="label">${key}</td><td class="value">${value}</td></tr>`
    //     }
    //   }
    //   table = table + "</tr></table>"
    //   return html + table
    // }

    // voor nu geen (i) op de Card
    // showMeta(item) {
    //   API.get(`/getcimeta/${item.uuid}`).then(r => {
    //     console.log("r: ", r)

    //     this.$q.dialog({
    //       title: '<i class="fa fa-info"></i>&nbsp;Meta information',
    //       message: this.renderMeta(r.data.data),
    //       html: true,
    //       ok: {
    //         color: "primary"
    //       }
    //     })
    //   })
    //}
  }
};
</script>
